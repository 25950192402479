<template>
  <cms-product-recommendations-elevate
    v-if="content.name && content.name.indexOf('[elevate]') >= 0"
    :content="updatedContent"
  />
  <cms-product-recommendations-standard v-else :content="updatedContent" />
</template>

<script setup lang="ts">
import type { ProductRecommendations } from '#types/components/cms/product-recommendations'

const props = defineProps<{
  content: ProductRecommendations
}>()

const updatedContent = ref<ProductRecommendations>({ ...props.content })

watch(
  () => props.content.recommendedProducts,
  // RecToken can change but we need to make sure to have the same impression and click
  (newProducts, oldProducts) => {
    if (newProducts?.length && oldProducts?.length) {
      updatedContent.value.recommendedProducts = newProducts.map((product, i) => ({
        ...product,
        recToken: oldProducts[i]?.id === product.id ? oldProducts[i].recToken : product.recToken
      }))
    }
    else {
      updatedContent.value.recommendedProducts = newProducts
    }
  }
)
</script>
